import { useState } from 'react'
// @ts-ignore
import placeholderDark from '#images/image-placeholder-dark.jpg'
import placeholderLight from '#images/image-placeholder.jpg'
import { useTheme } from '#app/utils/providers/themeProvider'

const Image = ({
	containerClassName,
	className,
	imageUrl,
	imageAlt,
}: {
	containerClassName?: string
	className: string
	imageUrl?: string
	imageAlt: string
}) => {
	const [theme] = useTheme()
	const [imageLoaded, setImageLoaded] = useState(true)
	const [imageSrc, setImageSrc] = useState(
		imageUrl ? imageUrl : theme === 'dark' ? placeholderDark : placeholderLight,
	)

	const handleImageError = () => {
		setImageLoaded(false)
		setImageSrc(theme === 'dark' ? placeholderDark : placeholderLight)
	}

	// console.log('IMAGEURL', imageUrl, imageSrc)

	return (
		<div className={containerClassName}>
			{imageLoaded ? (
				<img
					className={className}
					src={imageSrc}
					alt={imageAlt}
					onError={handleImageError}
				/>
			) : (
				<img
					className={className}
					src={theme === 'dark' ? placeholderDark : placeholderLight}
					alt={imageAlt}
				/>
			)}
		</div>
	)
}

export default Image

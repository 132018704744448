/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useTheme } from './utils/requestInfo.ts'
import {
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	json,
	useNavigation,
	useLoaderData,
	useRouteError,
} from '@remix-run/react'
import { withSentry } from '@sentry/remix'
import { captureRemixErrorBoundaryError } from '@sentry/remix'
import {
	type LoaderFunctionArgs,
	// type HeadersFunction,
	type LinksFunction,
	type MetaFunction,
} from '@remix-run/node'
// import { makeTimings } from './utils/timing.server.ts'
import { getToast } from './utils/toast.server.ts'
import { Toaster } from './components/dynamic/toaster.tsx'
import { ClientHintCheck, getHints } from './utils/client-hints.tsx'
import { combineHeaders, getDomainUrl } from './utils/misc.server.ts'
import { useNonce } from './utils/nonceProvider.ts'
import { Web3Provider } from './components/dynamic/web3Provider.tsx'
import AppWithLayout from './components/dynamic/appWithLayout.tsx'
import { EpicToaster } from './components/dynamic/sonner.tsx'
import { GeneralErrorBoundary } from './components/common/errorBoundary.tsx'
import { csrf } from './utils/csrf.server.ts'
import appStyles from './styles/index.css?url'
import dynamicStyles from './styles/dynamic.css?url'
import fontStyleSheetUrl from './styles/font.css?url'
import { commitSession, getSession } from './utils/sessions.ts'
import clsx from 'clsx'
import {
	ThemeProvider,
	useTheme as useProviderTheme,
	type Theme,
} from './utils/providers/themeProvider.tsx'
import { getThemeSession } from './utils/providers/providerTheme.server.ts'
import { offlineMode } from './utils/offlineMode.server.ts'
import { ExternalScripts } from 'remix-utils/external-scripts'
// import { getVercelDomainUrl } from './utils/misc.tsx'
import farcastEmbedStyles from 'react-farcaster-embed/dist/styles.css?url'
import Loader from './components/loaders/loader'
import jsonViewLight from 'react18-json-view/src/style.css?url'
import jsonViewDark from 'react18-json-view/src/dark.css?url'
import skeletonCSS from 'react-loading-skeleton/dist/skeleton.css?url'
import carouselCSS from 'react-responsive-carousel/lib/styles/carousel.min.css?url'
import tweetCSS from 'react-tweet/theme.css?url'
import twitterCSS from './styles/twitter.css?url'
import generateRootMeta from './utils/meta/rootMeta.ts'
import { AnalyticsProvider } from './utils/analytics/segmentProvider'

// CSS and Icons and other static files are served from here
export const links: LinksFunction = () => {
	return [
		{ rel: 'stylesheet', href: appStyles },
		{ rel: 'stylesheet', href: dynamicStyles },
		{ rel: 'stylesheet', href: fontStyleSheetUrl },
		{ rel: 'stylesheet', href: farcastEmbedStyles },
		{ rel: 'stylesheet', href: jsonViewLight },
		{ rel: 'stylesheet', href: jsonViewDark },
		{ rel: 'stylesheet', href: skeletonCSS },
		{ rel: 'stylesheet', href: carouselCSS },
		{ rel: 'stylesheet', href: tweetCSS },
		{ rel: 'stylesheet', href: twitterCSS },
	].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data, location }) => {
	const origin = data?.requestInfo.origin
	const metaPayload = generateRootMeta({ path: location.pathname, origin })
	return metaPayload
}

// Data loader for App Root
export async function loader({ request }: LoaderFunctionArgs) {
	// const timings = makeTimings('root loader')
	const { toast, headers: toastHeaders } = await getToast(request)
	const [csrfToken, csrfCookieHeader] = await csrf.commitToken()
	const cookieHeader = request.headers.get('Cookie')
	const offlineModeCookie = (await offlineMode.parse(cookieHeader)) || {}

	const session = await getSession(request.headers.get('cookie'))

	const sessionUserId = session.get('userId')
	const sessionAddress = session.get('address')
	const sessionUserName = session.get('userName') || ''

	const user: { name: string; id: string; address: string } | undefined =
		sessionUserId && sessionAddress
			? {
					name: sessionUserName,
					id: sessionUserId,
					address: sessionAddress,
				}
			: undefined

	const sessionHeaders = await commitSession(session)
	const themeSession = await getThemeSession(request)

	const url = new URL(request.url)
	const searchParams = url.searchParams
	if (searchParams.get('offlineMode')) {
		offlineModeCookie.offlineMode = searchParams.get('offlineMode')
	}

	const APP_NAME = import.meta.env.VITE_APP_NAME
	const APP_DESCRIPTION = import.meta.env.VITE_APP_DESCRIPTION
	const APP_URL = import.meta.env.VITE_APP_URL
	const MODE = import.meta.env.VITE_NODE_ENV
	const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
	const DYNAMIC_ENVIRONMENT_ID = import.meta.env.VITE_DYNAMIC_ENVIRONMENT_ID
	const DYNAMIC_API_KEY = import.meta.env.VITE_DYNAMIC_API_KEY
	const GA_TRACKING_ID = import.meta.env.VITE_GA_TRACKING_ID
	const SEGMENT_API_KEY = import.meta.env.VITE_SEGMENT_API_KEY
	const NODE_ENV = import.meta.env.VITE_NODE_ENV
	const pathname = new URL(request.url).pathname
	const referrer = request.headers.get('referer')
	let referrerPath = '/'

	if (referrer) {
		const url = new URL(referrer)
		const referrerDomain = url.origin
		if (referrerDomain === APP_URL) {
			referrerPath = url.pathname
		}
	}

	return json(
		{
			user,
			requestInfo: {
				hints: getHints(request),
				origin: getDomainUrl(request),
				path: pathname,
				userPrefs: {
					theme: themeSession.getTheme(),
					offlineMode: offlineModeCookie.offlineMode,
				},
				referrerPath,
			},
			ENV: {
				APP_NAME,
				APP_DESCRIPTION,
				APP_URL,
				MODE,
				SENTRY_DSN,
				DYNAMIC_ENVIRONMENT_ID,
				DYNAMIC_API_KEY,
				GA_TRACKING_ID,
				SEGMENT_API_KEY,
				NODE_ENV,
			},
			toast,
			csrfToken,
		},
		{
			headers: combineHeaders(
				// { 'Server-Timing': timings.toString() },
				toastHeaders,
				{ 'Set-Cookie': sessionHeaders },
				csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : null,
				{ 'Set-Cookie': await offlineMode.serialize(offlineModeCookie) },
			),
		},
	)
}

// Headers are populated from here for server timing for debugging and optimization
// export const headers: HeadersFunction = ({ loaderHeaders }) => {
// 	const headers = {
// 		'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
// 	}
// 	return headers
// }

function Document({
	children,
	nonce,
	theme = 'light',
	env = {},
}: {
	children: React.ReactNode
	nonce: string
	theme?: Theme
	env?: Record<string, string>
}) {
	return (
		<html lang="en" className={clsx('h-full overflow-x-hidden', theme)}>
			<head>
				<meta charSet="utf-8" />
				<meta httpEquiv="x-ua-compatible" content="ie=edge" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<Meta />
				<Links />
				<ClientHintCheck nonce={nonce} />
				{!env.GA_TRACKING_ID ? null : (
					<>
						<script
							async
							src={`https://www.googletagmanager.com/gtag/js?id=${env.GA_TRACKING_ID}`}
						/>
						<script
							async
							id="gtag-init"
							dangerouslySetInnerHTML={{
								__html: `
									window.dataLayer = window.dataLayer || [];
									function gtag(){dataLayer.push(arguments);}
									gtag('js', new Date());

									gtag('config', '${env.GA_TRACKING_ID}', {
									page_path: window.location.pathname,
									});
								`,
							}}
						/>
					</>
				)}
			</head>
			<body
				className={clsx('bg-background text-foreground')}
				suppressHydrationWarning
			>
				<AnalyticsProvider>{children}</AnalyticsProvider>
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(env)}`,
					}}
				/>
				<Toaster />
				<ScrollRestoration nonce={nonce} />
				<ExternalScripts />
				<Scripts nonce={nonce} />
			</body>
		</html>
	)
}

// App component to render w/ app outlet inside page template
function App() {
	const data = useLoaderData<typeof loader>()
	const nonce = useNonce()
	const [theme] = useProviderTheme()
	const navigation = useNavigation()
	const dynamicId = data.ENV.DYNAMIC_ENVIRONMENT_ID

	return (
		<Document nonce={nonce} theme={theme} env={data.ENV}>
			<Web3Provider dynamicId={dynamicId} theme={theme || 'light'}>
				<AppWithLayout
					theme={theme}
					offlineMode={data.requestInfo.userPrefs.offlineMode}
					referrerPath={data.requestInfo.referrerPath}
					env={data.ENV}
				>
					{navigation.state === "loading" ? (
						<div className="fixed inset-0 flex items-center justify-center z-50">
							<Loader size="medium" />
						</div>
					) : <Outlet /> }
				</AppWithLayout>
			</Web3Provider>
			<EpicToaster closeButton position="top-center" theme={theme} />
		</Document>
	)
}

function AppWithThemeProvider() {
	const theme = useTheme()
	return (
		// @ts-ignore
		<ThemeProvider specifiedTheme={theme}>
			<App />
		</ThemeProvider>
	)
}

export default withSentry(AppWithThemeProvider)

export function ErrorBoundary() {
	// the nonce doesn't rely on the loader so we can access that
	const nonce = useNonce()

	// NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
	// likely failed to run so we have to do the best we can.
	// We could probably do better than this (it's possible the loader did run).
	// This would require a change in Remix.

	// Just make sure your root route never errors out and you'll always be able
	// to give the user a better UX.
	const error = useRouteError()
	captureRemixErrorBoundaryError(error)

	return (
		<Document nonce={nonce}>
			<GeneralErrorBoundary />
		</Document>
	)
}

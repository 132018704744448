import Button from '../buttons/button'
import {
	TypographyHeading,
	TypographyLabel,
	TypographyCaps,
	TypographyParagraph,
} from '../typography'
import { type artist, type collection, type nft } from '#types/loreTypes.tsx'
import Hero from './hero.tsx'
import IndexContent from './indexContent.tsx'
import BaseLogo from '../common/logos/BaseLogo.tsx'
import EthereumLogo from '../common/logos/EthereumLogo.tsx'
import SolanaLogo from '../common/logos/SolanaLogo.tsx'
import ShapeLogo from '../common/logos/ShapeLogo.tsx'
import ZoraLogo from '../common/logos/ZoraLogo.tsx'
import TezosLogo from '../common/logos/TezosLogo.tsx'
import { ArrowRightIcon, RollupIcon } from '../icons/index.ts'
import learnmore from '#images/learnmore.webp'
import PolygonLogo from '../common/logos/PolygonLogo.tsx'
import onelovedaobanner from '#images/onelovedaobanner.webp'
import onelovedaopfp from '#images/onelovedaopfp.webp'
import rollupsbanner from '#images/rollupsbanner.jpg'
import { Link } from '@remix-run/react'
import InternalLink from '../common/internalLink.tsx'
import VideoPlayer from '../common/videoPlayer.tsx'
import videoPlaceholder from '#images/videoPlaceholder.webp'
import feedVideo from '#videos/loreFeedVideo.mp4'
import { useTheme } from '#app/utils/providers/themeProvider'

export default function Home({
	artists,
	collections,
	nfts,
	atomicSignUrl,
}: {
	artists: artist[]
	collections: collection[]
	nfts: nft[]
	atomicSignUrl: string
}) {
	const [theme] = useTheme()

	return (
		<>
			<div className="bg-white dark:bg-black">
				<div className="mx-auto flex flex-col items-center pb-8 md:pb-12">
					<Hero />
				</div>
			</div>
			<div className="mx-auto bg-white dark:bg-black">
				<div className="mx-0 max-w-[1440px] px-6 pb-8 sm:px-6 md:mx-16 md:px-12 md:pb-12 lg:mx-20 lg:px-24 xl:mx-auto">
					<div className="mt-8 flex flex-col items-center justify-center gap-y-16">
						<TypographyCaps
							size="medium"
							additionalClasses="text-textSecondaryLight dark:text-textSecondaryLight"
						>
							Chains we support
						</TypographyCaps>
						<div className="grid grid-flow-col grid-rows-4 gap-x-8 gap-y-8 text-textPrimaryLight dark:text-textPrimaryDark sm:grid-rows-1 md:gap-x-16">
							<div>
								<SolanaLogo />
							</div>
							<div>
								<EthereumLogo />
							</div>
							<div>
								<BaseLogo />
							</div>
							<div>
								<ZoraLogo />
							</div>
							<div>
								<TezosLogo />
							</div>
							<div>
								<PolygonLogo />
							</div>
							<div>
								<ShapeLogo />
							</div>
						</div>
						<div className="flex flex-col items-center gap-x-2 gap-y-2 sm:flex-row">
							<TypographyLabel
								size="medium"
								additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
							>
								Coming Soon:
							</TypographyLabel>
							<TypographyLabel
								size="medium"
								additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
							>
								Arbitrum, Optimism
							</TypographyLabel>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-white dark:bg-black">
				<div className="mx-0 max-w-[1440px] px-6 py-16 sm:px-6 md:mx-16 md:px-12 lg:mx-20 lg:px-24 xl:mx-auto">
					<div className="flex flex-col gap-x-6 gap-y-4 md:flex-row">
						<div className="w-full px-10 md:w-1/2">
							<div className="overflow-hidden rounded-[24px] border border-textInactiveDark">
								<div className="-my-4">
									<VideoPlayer src={feedVideo} placeholder={videoPlaceholder} />
								</div>
							</div>
						</div>
						<div className="flex w-full flex-col justify-center gap-y-6 md:w-1/2">
							<div className="flex flex-col gap-y-2">
								<TypographyCaps
									size="small"
									additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
								>
									Lore feed
								</TypographyCaps>
								<TypographyHeading
									size="large"
									isBold={true}
									additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark"
								>
									Follow the Latest Lore Additions
								</TypographyHeading>
							</div>
							<TypographyParagraph
								size="medium"
								additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
							>
								Explore the timeline of all attestations signed on Atomic Lore.
								Discover the rich history, stories, and provenance behind each
								NFT, as recorded in real-time.
							</TypographyParagraph>
							<TypographyParagraph
								size="medium"
								additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
							>
								Click to see the latest updates and immerse yourself in the
								ongoing narrative of the digital art world.
							</TypographyParagraph>
							<InternalLink
								to="/feed"
								additionalClasses="w-full sm:w-auto mt-4"
							>
								<Button variant="primary" additionalClasses="w-full sm:w-auto">
									Explore Feed
								</Button>
							</InternalLink>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-white dark:bg-black">
				<div className="mx-0 max-w-[1440px] px-6 py-16 sm:px-6 md:mx-16 md:px-12 lg:mx-20 lg:px-24 xl:mx-auto">
					<IndexContent
						artists={artists}
						collections={collections}
						nfts={nfts}
						atomicSignUrl={atomicSignUrl}
					/>
				</div>
			</div>
			<div className="bg-white dark:bg-black">
				<div className="mx-0 max-w-[1440px] px-6 py-32 sm:px-6 md:mx-16 md:px-12 lg:mx-20 lg:px-24 xl:mx-auto">
					<div className="flex flex-col gap-x-24 gap-y-8 sm:flex-row">
						<div className="sm:w-1/2">
							<img
								className="h-full object-cover"
								src={onelovedaobanner}
								alt="One Love NYC Art Exhibition"
							/>
						</div>
						<div className="flex flex-col justify-center gap-y-6 sm:w-1/2">
							<div className="flex flex-row">
								<img
									className="h-16 w-16 rounded-full object-cover"
									src={onelovedaopfp}
									alt="One Love Dao"
								/>
								<div className="flex-flex-col ml-4">
									<TypographyCaps
										size="small"
										additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
									>
										Featured event
									</TypographyCaps>
									<TypographyHeading
										size="large"
										isBold={true}
										additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark"
									>
										One Love NYC Art Exhibition
									</TypographyHeading>
								</div>
							</div>
							<TypographyParagraph
								size="medium"
								additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
							>
								On April 1st, 2024, ONELOVEARTDAO's "One Love Exhibition"
								featured 112 NFT artworks from the community, each meticulously
								documented onchain using Atomic Lore's new Roll-Up Attestation
								for Proof of Exhibition. This feature securely signs and tracks
								each piece, ensuring easy verification of where and when the
								artwork was displayed.
							</TypographyParagraph>
							<TypographyParagraph
								size="medium"
								additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
							>
								By partnering with Atomic Form, ONELOVEARTDAO has pioneered the
								implementation of multi-chain Proof of Exhibition, setting a new
								standard in the NFT space. The future of digital art is here,
								and ONELOVEARTDAO is leading the way.
							</TypographyParagraph>
							<div className="flex flex-row items-center gap-x-6">
								<a href="https://www.atomiclore.io/metadataProfile/event/Qma9YLcwt8RNyBEmjN3Fzv8on4VYrrzXBgtTonwwbTygQn">
									<Button variant="primary" size="medium">
										View Event
									</Button>
								</a>
								<a href="https://www.atomicform.com/support">
									<Button variant="tertiary" size="medium">
										Ready to Secure Your Art’s Legacy?
									</Button>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-white dark:bg-black">
				<div className="mx-0 max-w-[1440px] px-6 py-32 sm:px-6 md:mx-16 md:px-12 lg:mx-20 lg:px-24 xl:mx-auto">
					<div className="flex flex-col gap-x-48 gap-y-8 sm:flex-row">
						<div className="flex flex-col justify-center gap-y-6 sm:w-1/2">
							<TypographyCaps
								size="small"
								additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
							>
								Now in beta
							</TypographyCaps>
							<div className="flex flex-row items-center text-textPrimaryLight dark:text-textPrimaryDark">
								<RollupIcon />
								<div className="flex-flex-col ml-2">
									<TypographyHeading
										size="large"
										isBold={true}
										additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark"
									>
										Rollup Attestations
									</TypographyHeading>
								</div>
							</div>
							<div className="w-16 border-b border-textInactiveLight dark:border-textInactiveDark" />
							<div>
								<TypographyParagraph
									size="medium"
									additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
								>
									Are you an artist or curator with thousands of exhibition
									records that need to be added onchain?
								</TypographyParagraph>
								<TypographyParagraph
									size="medium"
									additionalClasses="text-textSecondaryLight dark:text-textSecondaryDark"
								>
									Our new Roll-Up Feature allows you to securely sign bulk
									imports from spreadsheets or databases.
								</TypographyParagraph>
							</div>
							<div className="flex flex-col items-center gap-y-2">
								<Link
									to="https://www.atomicform.com/rollup"
									target="_blank"
									className="w-full"
								>
									<Button
										variant="primary"
										size="medium"
										additionalClasses="w-full"
									>
										Learn More
									</Button>
								</Link>
								<Link
									to="https://calendly.com/atomicform-melissa/30min"
									target="_blank"
									className="w-full"
								>
									<Button
										variant="secondary"
										size="medium"
										additionalClasses="w-full"
									>
										Schedule a Demo
									</Button>
								</Link>
							</div>
						</div>
						<div className="sm:w-1/2">
							<img
								className="h-full rounded-md object-cover"
								src={rollupsbanner}
								alt="Rollup Attestations"
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="bg-white dark:bg-black">
				<div className="mx-auto mb-24 flex max-w-[1440px] flex-col items-center justify-center px-6 py-8 sm:px-6 md:px-12 md:py-12 lg:px-24 xl:mx-auto">
					<div className="flex flex-col items-center gap-x-6 gap-y-6 rounded-md bg-uiSecondaryLight p-4 dark:bg-uiSecondaryDark sm:flex-row">
						<img
							src={learnmore}
							alt="Learn More"
							className="w-full rounded-md object-cover sm:h-24 sm:w-24"
						/>
						<div className="flex flex-col">
							<TypographyParagraph
								size="large"
								isBold={true}
								additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark"
							>
								Discover the Power of Atomic Lore
							</TypographyParagraph>
							<TypographyParagraph
								size="medium"
								additionalClasses="text-textPrimaryLight dark:text-textPrimaryDark"
							>
								Curious about how Atomic Lore works? Learn to immortalize your
								art’s story with verified, permanent records of exhibitions,
								social media mentions, and provenance.
							</TypographyParagraph>
						</div>
						<Link
							to="https://www.atomicform.com/lore"
							target="_blank"
							className="w-full sm:w-fit"
						>
							<Button
								variant="secondary"
								size="medium"
								rightIcon={<ArrowRightIcon />}
								additionalClasses="whitespace-nowrap w-full"
							>
								Learn More
							</Button>
						</Link>
					</div>
				</div>
			</div>
		</>
	)
}

import { useEffect, useRef, useState } from 'react'

const VideoPlayer = ({
	src,
	threshold = 0.5,
	placeholder,
	...props
}: {
	/** Source URL for the video */
	src: string
	/** Visibility threshold for autoplay (0 to 1) */
	threshold?: number
	/** Placeholder image for lazy loading */
	placeholder?: string
}) => {
	const videoRef = useRef<HTMLVideoElement | null>(null)
	const [isObserverSupported, setIsObserverSupported] = useState(true)

	useEffect(() => {
		const video = videoRef.current

		if (!video) return

		// Check if the IntersectionObserver API is supported
		if (!('IntersectionObserver' in window)) {
			setIsObserverSupported(false)
			return
		}

		const handleIntersection: IntersectionObserverCallback = ([entry]) => {
			if (entry.isIntersecting) {
				video.play().catch(err => console.error('Failed to autoplay:', err))
			} else {
				video.pause()
			}
		}

		const observer = new IntersectionObserver(handleIntersection, {
			threshold,
		})

		observer.observe(video)

		return () => {
			observer.disconnect()
		}
	}, [threshold])

	return (
		<div style={{ position: 'relative', overflow: 'hidden' }}>
			<video
				ref={videoRef}
				src={src}
				muted
				playsInline
				loop
				poster={placeholder}
				controls={!isObserverSupported}
				style={{
					width: '100%',
					height: 'auto',
					display: 'block',
					objectFit: 'contain',
					maxWidth: '100%',
					maxHeight: '100%',
				}}
				{...props}
			>
				Your browser does not support the video tag.
			</video>
		</div>
	)
}

export default VideoPlayer

import clsx from 'clsx'
// @ts-ignore
import loaderLight from '#images/loader-light.webp'
// @ts-ignore
import loaderDark from '#images/loader-dark.webp'
import { useTheme } from '#app/utils/providers/themeProvider'

const Loader = ({
	size = 'medium',
}: {
	size?: 'small' | 'medium' | 'large'
}) => {
	const [theme] = useTheme()
	return (
		<div
			className={clsx(
				size === 'large' && 'w-80',
				size === 'medium' && 'w-40',
				size === 'small' && 'w-20',
			)}
		>
			<img
				src={theme === 'light' ? loaderLight : loaderDark}
				className="block"
			></img>
		</div>
	)
}

export default Loader
